/* Module: S */
.homepage-subfooter-cta {
    display: block;
    &__item-inner-wrapper {
        text-decoration: none;
        max-width: pxToRem(200);
        text-align: center;
    }
}

.homepage-subfooter-cta__item {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    @include sq-justify-content(center);
    padding: 2rem;
    text-align: center;
    &:first-child {
        @include sq-flex-box();
        background: #f2f2f2;
    }
    &::after {
        display: none;
    }
}

.homepage-subfooter-cta__title {
    font-family: $base__font-family-medium;
    font-size: 1rem;
    line-height: 1.4;
    &:hover {
        color: #000;
    }
}

.homepage-subfooter-cta__link {
    display: none;
    font-size: pxToRem(15);
}