/* Module: S */
.sidebar__menu {
    position: relative;
}

.accordion {
    position: relative;
    //@include drop-shadow();
}

.accordion__block {
    margin-bottom: pxToRem(20);
}

.accordion__heading {
    margin: 0;
    width: 100%;
}

.tabs {
    //@include drop-shadow(.1);
    display: inherit;
    margin-bottom: 3rem;
}

.tab__content {
    padding: pxToRem(30) pxToRem(30);
}

.mobile-tabs__accordion {
    display: none;
}

.toc__box {
    width: $toc-box__max-width;
    display: inline-block;
    float: right;
    margin-left: 2rem;
    margin-bottom: 2rem;
}

.toc__list {
    display: block;
}

.toc__link {
    width: 100%;
    max-width: $toc-box__max-width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rhs__column {
    margin-top: 2rem;
    margin-bottom: .75rem;
    @include sq-flex-box;
    @include sq-flex-wrap(wrap);
    @include sq-justify-content(space-between);
    // @include sq-flex-basis(100%);
    .is-listing+& {
        display: block;
    }
}

.rhs__panel {
    width: calc(50% - .75rem);
}

// Safari have some problems with proper flex wrapping
@media screen and (max-width: 63.9375em) {
    /* stylelint-disable */
    _::-webkit-full-page-media,
    _:future,
     :root .rhs__column {
        @include sq-flex-basis(auto);
    }
    _::-webkit-full-page-media,
    _:future,
     :root .main__content {
        @include sq-flex-basis(auto);
    }
    _::-webkit-full-page-media,
    _:future,
     :root .is-listing+.rhs__column {
        @include sq-flex-basis(auto);
    }
    /* stylelint-enable */
}