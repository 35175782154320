/* Module: S */
.inside-header {
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #ccc;
    h1 {
        font-family: $base__font-family;
        color: $color__primary;
        font-size: pxToRem(28);
        line-height: 1.3;
    }
    h2 {
        font-family: $secondary__font-family;
        font-style: italic;
        font-weight: normal;
        color: $color__secondary;
        font-size: pxToRem(18);
    }
}