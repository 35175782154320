/* Module: S */
.homepage-updates__wrapper-outer {
    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: $color__primary;
        margin-top: 12rem;
    }
}

.homepage-updates__wrapper {
    padding-bottom: 3rem;
}

.homepage-updates {
    @include sq-flex-box();
    @include sq-flex-direction(row);
    @include sq-justify-content(space-between);
    flex-wrap: wrap;
}

.homepage-updates-column {
    @include sq-flex(1 1 40%);
    @include sq-flex-box();
    @include sq-flex-direction(column);
    max-width: calc((100% / 2) - 12px);
}

.homepage-updates__item {
    // height: $page__max-width/4;
    height: 14.75rem;
    padding: 1.5rem;
    margin-bottom: 24px;
    &:hover {
        //@include sq-scale(1.2);
        //@include sq-transition(all .3s);
        //@include drop-shadow(.75, .5rem);
        //color: $color__primary; 
    }
}

.homepage-updates__header {
    border-top: none;
    padding-top: 0;
}

.homepage-updates__item-heading {
    font-size: pxToRem(44);
    max-width: pxToRem(200);
    height: 15rem;
    line-height: 1.2;
    margin-bottom: 2.05rem;
    padding-left: 0;
    padding-top: 1rem;
    &::after {
        width: 30px;
        margin-top: 25px;
    }
}

.homepage-updates__item-plain {
    background: #f2f2f2;
    @include sq-transition(all .25s);
    &:hover {
        @include sq-transition(all .25s);
        background: #ccc;
    }
}

.homepage-updates__item-fancy {
    // height: 36.2rem;
    height: 31.9rem;
    color: #fff;
    background: $color__primary;
    @include sq-transition(opacity .25s);
    &:focus {
        color: #fff;
    }
    &:hover {
        color: #fff;
        transition: sq-transition(opacity .25s);
        .homepage-updates__bg {
            @include sq-transition(opacity .25s);
            opacity: .65;
        }
    }
    .homepage-updates__header {
        border-top: none;
        border-bottom: 1px solid #fff;
        padding-top: 0;
        &-type {
            color: #fff;
        }
        &-date {
            color: #fff;
        }
    }
    .homepage-updates__bg {
        display: block;
        @include sq-transition(opacity .5s);
        opacity: 1;
        &::before {
            background: rgba(0, 0, 0, .5);
            /* Old browsers */
            background: -moz-linear-gradient(bottom, rgba(0, 0, 0, .9) 20%, rgba(0, 0, 0, 0) 60%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .9) 20%, rgba(0, 0, 0, 0) 60%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to top, rgba(0, 0, 0, .9) 20%, rgba(0, 0, 0, 0) 60%);
        }
        &::after {
            background: rgba(0, 0, 0, .5);
            /* Old browsers */
            background: -moz-linear-gradient(top, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, 0) 30%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, 0) 30%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, 0) 30%);
        }
    }
}

.btn-load-more {
    display: none;
}