// TOP NAV
$top-nav__bg: #fff;
$top-nav__color: #777676;
$main-menu__bg: #fff;
$main-nav__color: #000;
$main-nav__color--hover: $color__secondary;
$main-nav-top-bar__color: $main-nav__color--hover;
$header-search-input__border: #fff;
$header-search-button__color: #000;
$header-search-mobile__bg: #fff;
$header-search-focus__bg: #fff;
$menu-arrow__color: $color__primary;
$mega-menu__bg: #fff;
$mega-menu-border__color: #ddd;
$mega-menu-link__color: $main-nav__color;
$mega-menu-sub-nav-title__color: $color__primary;
$mega-menu-sub-nav-link__color: #58595b;
$mobile-menu-lvl1__bg: #fff;
$mobile-menu-lvl2__bg: #fff;
$mobile-menu-lvl3__bg: #fff;