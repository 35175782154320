body {
    font-size: pxToRem($base__font-pixel);
}

.wrapper,
.main-content__wrapper {
    @include sq-flex(1 1 auto);
    @include sq-flex-direction(row);
    @include sq-align-items(flex-start);
}

.main-column__wrapper {
    min-width: 1px;
    @include sq-flex(0 1 100%);
}

.main-content__wrapper {
    @include sq-flex-wrap(wrap);
    max-width: pxToRem(787);
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}

.main__content {
    width: auto;
    min-width: 1px;
    padding-right: 15%;
    @include sq-flex(1 0 0%);
    h2 {
        font-size: pxToRem(44);
        line-height: 1;
    }
    h3 {
        font-size: pxToRem(36);
        line-height: 1;
    }
    h4 {
        font-size: pxToRem(28);
        line-height: 1;
    }
    h5 {
        font-size: pxToRem(18);
        line-height: 1;
    }
    blockquote {
        font-size: pxToRem(24);
    }
    .text-featured {
        font-size: pxToRem(22);
    }
}

_::-webkit-full-page-media,
_:future,
 :root .tool-box {
    @include sq-flex-basis(100%);
}

//
_::-webkit-full-page-media,
_:future,
 :root .tool-box+.main__content {
    @include sq-flex-basis(1px);
}

.sidebar__menu-toggle {
    // display: none;
    font-size: pxToRem(22);
    padding: pxToRem(30) pxToRem(30) pxToRem(10) pxToRem(30);
    line-height: 1.25;
    &::after {
        display: none;
    }
    cursor: default;
}

.sidebar__menu-list:not(.menu__level-2, .menu__level-3, .menu__level-4, .menu__level-5) {
    display: inherit;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
    padding-bottom: 2.3rem;
}

.sidebar__menu-toggle-secondary {
    .sidebar__menu-title {
        max-width: pxToRem(225);
    }
}

.sidebar__menu-list-secondary {
    .sidebar__menu-item-subtitle {
        padding-top: 1rem;
    }
}

.tools-social {
    .landing-image__container+.wrapper & {
        margin-top: 1rem;
    }
}

.tool-box {
    //@include sq-align-items(flex-start);
    display: block;
    margin-bottom: pxToRem(57.6);
    margin-top: pxToRem(40);
    .landing-image__container+.wrapper & {
        // margin-top: -4rem;
        // margin-bottom: 4rem;
    }
}


/*
--------------------
Modules
--------------------
*/