/* Module: S */
.general-listing__info {
    color: $color__primary;
    font-size: pxToRem(14);
    strong {
        font-family: $base__font-family-bold;
        font-weight: normal;
    }
}

.general-listing {
    @include sq-list-reset();
}

.general-listing__item {
    @include sq-transition(all .5s);
    // margin-bottom: pxToRem(40);
    padding-bottom: pxToRem(40);
    &::before {
        display: none;
    }
}

.general-listing__link {
    text-decoration: none;
    display: block;
    border-bottom: none !important ;
    @include add-focus-border();
}

.general-listing-body__heading {
    margin-bottom: .15rem !important; //override .main-content h2
    font-size: pxToRem(18) !important; //override .main-content h2
    margin-top: 0 !important; //override .main-content h2
    line-height: 1.4 !important;
    @include sq-transition(color .5s);


    .general-listing__link:hover &,
    .general-listing__link:focus & {
        color: $color__secondary;
    }
}

.general-listing-item__header {
    position: relative;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-bottom: .75rem;
    border-bottom: 1px solid #d8d6d6;
    border-top: 1px solid #d8d6d6;
    font-size: pxToRem(14);
}

.general-listing-header__category {
    @include sq-flex-basis(30%);
    font-family: $base__font-family-bold;
    color: $color__primary !important;
}

.general-listing-header__meta {
    font-family: $secondary__font-family;
    font-style: italic;
    &>div {
        margin-right: 20px;
    }
}

.general-listing-header__meta-texticon {
    @include sq-flex-box();
    @include sq-align-items(center);
    text-decoration: underline;
    svg {
        fill: #a8a8a8;
        height: pxToRem(15);
        max-width: pxToRem(15);
        margin-right: pxToRem(5);
    }
}

.general-listing-events {
    .general-listing-header__meta-texticon {
        text-decoration: none;
        &.general-listing-header__meta-texticon--location {
            color: $base__font-colour !important;
        }
    }
}

.general-listing-header__meta-text,
.general-listing-header__meta-texticon {
    display: inline-block;
    /*override .main__content a */
    font-weight: normal !important;
    color: $color__primary !important;
}

div.general-listing-header__meta-date {
    position: absolute;
    right: 0;
    top: 10px;
    margin-right: 0;
}

.general-listing-body__desc {
    color: #4f4f4f;
    font-weight: normal !important;
}

.sidebar__menu-item-has-form {
    padding-top: 1rem;
    margin-bottom: .5rem;
    .form__group {
        margin-bottom: 0;
        label {
            padding-left: 0;
            margin-bottom: 0;
            color: #fff;
        }
        .btn-filter {
            background: transparent;
            padding-right: 0;
        }
        .calendar__icon {
            fill: #fff;
            width: 11px;
        }
        .datepicker__wrap {
            display: block;
        }
        .datepicker__clear {
            color: #fff;
            right: pxToRem(15);
            top: pxToRem(22);
        }
    }
    .form__control,
    .form__control:focus {
        @include placeholder-color(#fff);
        padding-left: 0;
        color: #fff;
        background: transparent;
    }
    select {
        background-image: url('../mysource_files/ico-arrow-down.svg') !important; // override select.form__control
        background-position: center right 0 !important; // override select.form__control
        background-color: transparent;
        color: #fff;
        padding-left: 0;
        padding-right: 0;
        &:focus {
            background-color: transparent;
            color: #fff;
        }
        &::-ms-expand {
            display: none;
        }
        option {
            color: $color__primary;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.sidebar__menu-has-filters {
    background: $color__secondary !important;
    .sidebar__menu-item-has-form {
        margin-left: pxToRem(30);
        margin-right: pxToRem(30);
    }
    .sidebar__menu-list {
        margin-bottom: 0 !important;
    }
    button[type='submit'] {
        display: none;
        margin-bottom: pxToRem(30);
        margin-left: pxToRem(30);
    }
}