@font-face {
    font-family: 'GothamHTF-Medium';
    src: url('./mysource_files/GothamHTF-Medium.woff2') format('woff2'), url('./mysource_files/GothamHTF-Medium.woff') format('woff');
}
@font-face {
    font-family: 'GothamHTF-Bold';
    src: url('./mysource_files/GothamHTF-Bold.woff2') format('woff2'),url('./mysource_files/GothamHTF-Bold.woff') format('woff');
}
@font-face {
    font-family: 'GothamHTF-Book';
    src: url('./mysource_files/GothamHTF-Book.woff2') format('woff2'),url('./mysource_files/GothamHTF-Book.woff') format('woff');
}