/* Module: Breadcrumbs */
.breadcrumbs {
    display: none;
}

.breadcrumbs__list {
    @extend %list-reset;
    @include sq-flex-box;
    @include sq-flex-wrap(wrap);
}

.breadcrumbs__item {
    position: relative;
    margin-right: 0.55rem;
    padding-right: 1.65rem;
    font-family: $secondary__font-family;
    font-size: pxToRem(14);
    font-style: italic;

    &:not(.breadcrumbs__current)::after {
        content: '';
        position: absolute;
        top: pxToRem(9);
        right: .05rem;
        // left: 3.2rem;
        display: block;
        height: .75rem;
        width: 1rem;
        background: url('../mysource_files/ico-arrow-breadcrumbs.svg') no-repeat;
        // border-right: 3px solid $breadcrumbs-divider__color;
        // border-bottom: 3px solid $breadcrumbs-divider__color;
        // @include sq-transform(rotate(-45deg));
    }
}

.page-consultations-details .breadcrumbs__link,
.breadcrumbs__link {
    color: $breadcrumbs-links__color;
    text-decoration: none;
    display: block;
    font-weight: normal;
    border-bottom: none;
    padding-bottom: none;

    &:hover,
    &:focus {
        color: $breadcrumbs-links__color--hover;
        text-decoration: underline;
    }
}

.breadcrumbs__divider {
    @include sq-inline-block;
    margin: 0 5px;
}

.breadcrumbs__current {
    font-family: $base__font-family-medium;
    color: $breadcrumbs-links__color;
    font-style: normal;
    padding-right: 0;
    margin-right: 0;
}
