/* Module: S */
.homepage-infoblocks {
    margin-top: -3rem;
    padding-right: 0;
    padding-bottom: 3rem;
    &__item {
        margin-bottom: 2.5rem;
    }
    &__header {
        padding-top: 8rem;
        color: $color__primary;
        font-size: pxToRem(28);
        line-height: 1.29;
        margin-bottom: 2.5rem;
        &::after {
            margin-top: pxToRem(20);
        }
    }
    &__heading {
        font-size: pxToRem(18);
        line-height: pxToRem(30);
    }
    &__title {
        font-size: pxToRem(28);
        padding: 1.5rem 1rem .25rem;
    }
    &__image {
        height: pxToRem(290);
        width: pxToRem(712);
        margin-left: -8%;
    }
    &__icon {
        width: 30px;
        height: 30px;
    }
    &__content {
        display: block;
    }
}

#homepage-infoblocks[data-columns]::before {
    content: '2 .column.size-1of2';
    visibility: hidden;
}

.column {
    float: left;
}

.size-1of2 {
    width: calc(50% - 30px);
    &:nth-child(odd) {
        margin-right: 30px;
    }
}