/* Module: S */
$bigger-rhs__width: $rhs-column__width + 2rem;
$bigger-sidebar__width: $sidebar-menu__width + 2rem;

.rhs__column {
    @include sq-flex-basis($bigger-rhs__width);
}

.sidebar__menu {
    //@include sq-flex-basis($bigger-sidebar__width);
}
.map__description {
    width: 40%;
    position: absolute;
    bottom: pxToRem(-50);
    left: pxToRem(16);
}
.map__wrapper{
    position: relative;
    margin-bottom: calc(3rem + 50px);

}
