/* Module: S */
.homepage-updates__wrapper-outer {
    padding-top: pxToRem(39);
    &::before {
        height: 53.5rem;
    }
}

.homepage-updates {
    flex-wrap: nowrap;
}

.homepage-updates__wrapper {
    padding-bottom: 6rem;
}

.homepage-updates-column {
    @include sq-flex(1 1 23.2%);
    max-width: 23.2%;
}

.homepage-updates__item {
    margin-bottom: 2rem;
}