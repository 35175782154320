/* Module: S */
.homepage-updates__wrapper-outer {
    padding-top: pxToRem(18);
    position: relative;
}

.homepage-updates__wrapper {
    position: relative;
    width: 100%;
    // max-width: $page__max-width;
    max-width: 79rem;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

.homepage-updates-column {
    max-width: 100%;
    .animated {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: initial !important;
      animation-fill-mode: initial !important;
    }
}

.homepage-updates__item {
    @include sq-flex-box();
    @include sq-flex-direction(column);
    @include sq-justify-content(space-between);
    @include sq-transition(all .3s);
    margin-bottom: 3.5rem;
    height: auto;
    font-size: 1rem;
    text-decoration: none;
    @include add-focus-border();
}

.homepage-updates__header {
    @include sq-flex-box();
    @include sq-flex-direction(row);
    @include sq-justify-content(space-between);
    font-size: pxToRem(14);
    border-top: 1px solid #a8a8a8;
    border-bottom: 1px solid #a8a8a8;
    padding-top: .5rem;
    padding-bottom: .5rem;
    &-type {
        font-family: $base__font-family-bold;
        color: $color__primary;
    }
    &-date {
        font-family: $secondary__font-family;
        font-style: italic;
        color: $color__primary;
    }
}

.homepage-updates__item-heading {
    font-family: $secondary__font-family;
    font-style: italic;
    font-size: pxToRem(24);
    color: $color__primary;
    height: auto;
    line-height: 1;
    padding: 0;
    max-width: 100%;
    margin-bottom: 3rem;
    span {
        &::after {
            content: '';
            display: block;
            background: $color__secondary;
            height: 3px;
            width: 30px;
            margin-top: 22px;
            position: absolute;
        }
    }
}

.homepage-updates__item-plain {
    //background: #fff;
    color: #4f4f4f;
}

.homepage-updates__item-fancy {
    color: #4f4f4f;
    position: relative;
    overflow: hidden;
    &:focus {
        color: $color__primary;
    }
    .homepage-updates__header {
        border-top: 1px solid #a8a8a8;
        border-bottom: 1px solid #a8a8a8;
    }
    .homepage-updates__bg {
        display: none;
        height: 100%;
        width: pxToRem(500);
        position: absolute;
        background-size: cover !important;
        // margin-left: -40%;
        margin-left: -44%;
        top: 0;
        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, .5);
            /* Old browsers */
            background: -moz-linear-gradient(bottom, rgba(0, 0, 0, .2) 100%, rgba(0, 0, 0, .2) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .2) 100%, rgba(0, 0, 0, .2) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to top, rgba(0, 0, 0, .2) 100%, rgba(0, 0, 0, .2) 100%);
        }
        &::after {
            background: rgba(0, 0, 0, .5);
            /* Old browsers */
            background: -moz-linear-gradient(bottom, rgba(0, 0, 0, .2) 100%, rgba(0, 0, 0, .2) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .2) 100%, rgba(0, 0, 0, .2) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to top, rgba(0, 0, 0, .2) 100%, rgba(0, 0, 0, .2) 100%);
        }
        &-disableParallax {
            /* override parallax on mobile */
            transform: translateX(0) !important;
            // width: auto !important;
        }
    }
}

.homepage-updates__item-header-wrapper,
.homepage-updates__item-body {
    z-index: 1;
}

.homepage-updates__item-body {
    padding-top: 1rem;
}

.btn-load-more {
    display: block;
    width: 100%;
    height: auto;
    font-size: pxToRem(14);
    font-family: $secondary__font-family;
    font-style: italic;
    padding: 0;
    background: transparent;
    border: none;
    margin-bottom: 2rem;
    @include sq-transition(all .5s);
    &:hover {
        color: $color__secondary;
    }
    &::before {
        content: '';
        display: inline-block;
        background: url('../mysource_files/ico-reload.svg') no-repeat center center;
        width: 13px;
        height: 13px;
        margin-right: 10px;
    }
    &:hover::before {
        @include sq-rotate(360);
        @include sq-transition(all .5s);
    }
    &.btn-load-more-alt {
        text-align: left;
        border-bottom: 1px solid #a8a8a8;
        padding-bottom: 1rem;
    }
}