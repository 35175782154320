/* Module: S */
.top-nav,
.main-header__wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
}

.top-nav__item {
    padding: 0 1.5rem;
}

.logo__link {
    height: pxToRem(61);
    // width: pxToRem(235);
}

.header__logo {
    width: 14.6875rem;
    margin-right: auto;
}

.logo__link-text img {
    height: pxToRem(37);
}

//mobile buttons
.main-menu__btn,
.header-search__mobile-btn {
    margin-left: .5rem;
}

.header-search__form {
    position: absolute;
    // top: pxToRem(15);
    left: 0;
    width: 100%;
    display: none;
    background: #fff;
    height: pxToRem(70);
}