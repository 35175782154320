/* Module: S */
.detailed-guide .main-content__wrapper {
    position: relative;
    padding-right: pxToRem(110);
    display: flex;
    flex-flow: row wrap;
}


.detailed-guide .main__content {
    order: 1;
    width: 100%;
    flex-basis: 100%;
}

.detailed-guide .rhs__column {
    order: 4;
    position: absolute;
    right: 0;
    top: 0;
    max-width: pxToRem(245);
}

.detailed-guide .guide-links {
    order: 2;
    padding-right: 20%;
}

.detailed-guide .guide-content {
    order: 3;
    padding-right: 20%;
}

/* 2 column layout */
.detailed-guide-2-col .main-content__wrapper {
    position: relative;
    padding-right: pxToRem(110);
    display: flex;
    flex-flow: row wrap;
}

.detailed-guide-2-col .main__content {
    flex-basis: 100%;
    margin-right: 18rem;
    order: 1;
}

.detailed-guide-2-col .rhs__column {
    order: 4;
    position: absolute;
    right: 0;
    top: 0;
    max-width: pxToRem(245);
}

.detailed-guide-2-col .guide-links {
    flex-basis: pxToRem(240);
    margin-right: 1rem;
    order: 2;
}

.detailed-guide-2-col .guide-content {
    flex-basis: calc(100% - 290px);
    order: 3;
}