/* Module: S */
/* stylelint-disable */

.landscape-carousel__caption-wrapper {
    top: 80px;
}
/* stylelint-enable */

.landscape-carousel__image-wrapper {
    height: pxToRem(540);
}

.landscape-carousel__controls {
    bottom: pxToRem(80);
    width: 100%;
}

.landscape-carousel__image {
    height: pxToRem(500);
}

        

.landscape-carousel__caption {
    font-family: $base__font-family;
    width: 100%;
    max-width: 85%;
    // padding: 1.35rem 2rem;
    line-height: 1.35;
    &.is-video {
        // margin-bottom: 1.25rem;
        &::after {
            padding-left: pxToRem(18);
            padding-right: pxToRem(18);
        }
    }
}

.landscape-carousel__caption-preheading {
    font-size: pxToRem(24);
    padding-left: pxToRem(40);
    &::before {
        width: 27px;
        top: 16px;
        height: 3px;
    }
}

.landscape-carousel__caption-heading {
    font-size: pxToRem(44);
    line-height: pxToRem(58);
    margin-bottom: 1rem;
    padding-bottom: 0;
    border-bottom: 0;
    margin-right: 0.8rem;
}

.landscape-carousel__caption-content {
    max-width: 85%;
    font-size: pxToRem(16);
    line-height: pxToRem(26);
}

.landscape-carousel__item {
    &:before {
        background-image: url('../mysource_files/overlay-carousel-tablet.png');
    }
}