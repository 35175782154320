/* Module: S */
.detailed-guide,
.detailed-guide-2-col {
    .main__content {
        order: 1;
        padding-bottom: 0;
    }

    .rhs__column {
        order: 4;
    }

    .guide-links {
        order: 2;
    }

    .guide-content {
        order: 3;
        padding-bottom: 6rem;
    }
}
