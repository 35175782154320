/* Module: S */
.general-listing-body__heading {
    font-size: pxToRem(28) !important; //override .main-content h2
    margin-bottom: 1rem !important; //override .main-content h2
    line-height: 2.25rem;
}

.general-listing-item__header {
    margin-bottom: 1.5rem;
}

.sidebar__menu-has-filters {
    button[type='submit'] {
        display: block !important;
    }
}

.sidebar__menu-item-has-form {
  max-width: 250px !important;
}