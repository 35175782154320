/* Module: S */
.countdown-widget__container {
    position: absolute;
    z-index: 3;
    top: 0;
    color: #fff;
    background-color: rgba($color__secondary, 1);
    font-family: $base__font-family;
    font-weight: 1000;
    font-size: pxToRem(18);
    width: 100%;

    p {
        margin: pxToRem(14.5) 0;
    }

    .countdown__time {
        font-size: pxToRem(19.5);
    }

    .countdown__link {
        font-family: $secondary__font-family;
        color: rgba(99,181,41,1);
        text-decoration: none;
        font-style: italic;
    }
}
.landing-heading__container {
    &::before {
      background-image: url('../mysource_files/page header overlay.png');
      max-width: pxToRem(1920);
      margin: 0 auto;
    }
  }
