/* Module: S */
.homepage-subfooter-cta {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    @include sq-flex-direction(row);
    &__item-inner-wrapper {
        max-width: pxToRem(320);
    }
}

.homepage-subfooter-cta__item {
    @include sq-flex-box();
    @include sq-flex(1 1 50%);
    padding: 3rem 6rem;
    @include sq-justify-content(flex-start);
    padding-left: 8%;
    &:first-child {
        @include sq-justify-content(flex-end);
        padding-right: 8%;
    }
}

.homepage-subfooter-cta__title {
    font-size: pxToRem(28);
    margin-bottom: 1rem;
    line-height: 1.2;
}

.homepage-subfooter-cta__link {
    display: inline-block;
}