// COLORS PALETTE
$color__primary: #1e264a;
$color__secondary: #704b99;
$color__secondary-rgb: 112,75,153;
$color__tertiary: #1e264a;
$color__quaternary: #01183c;
// BASE FONT SETTINGS
$base__font-scale: 1; // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base__font-pixel: 16; // Used for font size calculations & conversions
$base__font-pixel-mobile: 14;
$base__font-colour: #4f4f4f;
$base__font-family: 'GothamHTF-Book',
helvetica,
arial,
sans-serif;
$base__font-family-bold: 'GothamHTF-Bold',
helvetica,
arial,
sans-serif;
$base__font-family-medium: 'GothamHTF-Medium',
helvetica,
arial,
sans-serif;
$base__line-height: 1.75rem;
$base__bg: #f2f2f2;
$wrapper__bg: #fff;
// SECONDARY FONT SETTINGS
$secondary__font-family: 'Noto Serif',
serif;
$heading__font-color: $color__tertiary;
$page__max-width: 79rem;
// GLOBAL LINKS
$global-link__color: $color__primary;
$global-link__color--hover: $color__primary;
// Selection highlighting
$content-selection__highlight: #A85CFF;
// BREADCRUMBS
$breadcrumbs-links__color: $color__primary;
$breadcrumbs-links__color--hover: $breadcrumbs-links__color;
$breadcrumbs-divider__color: $color__primary;
// sidebar MENU
$sidebar-menu__width: 15.3125rem; // 245px
$sidebar-menu__margin-right: 2rem; // This variable needs to have same unit as $rhs-column__margin-left
$sidebar-menu__border-bottom: #8568a7;
$sidebar-menu__mobile-toggler__color: #fff;
$sidebar-menu__mobile-toggler__color--active: $sidebar-menu__mobile-toggler__color;
$sidebar-menu__mobile-toggler__bg: $color__secondary;
$sidebar-menu__mobile-toggler__bg--active: $color__secondary;
$sidebar-menu__mobile-toggler-arrow__color: $sidebar-menu__mobile-toggler__color;
$sidebar-menu__mobile-toggler-arrow__color--active: $sidebar-menu__mobile-toggler-arrow__color;
$sidebar-menu__level-1__color: #fff;
$sidebar-menu__level-1__bg: $color__secondary;
$sidebar-menu__level-1__color--hover: $sidebar-menu__level-1__color;
$sidebar-menu__level-1__bg--hover: lighten($color__secondary, 5%);
$sidebar-menu__level-1__color--active: #fff;
$sidebar-menu__level-1__bg--active: $color__secondary;
$sidebar-menu__level-1-arrow__color: $sidebar-menu__level-1__color;
$sidebar-menu__level-2__color: #fff;
$sidebar-menu__level-2__bg: $color__secondary;
$sidebar-menu__level-2__color--hover: $sidebar-menu__level-2__color;
$sidebar-menu__level-2__bg--hover: lighten($sidebar-menu__level-2__bg, 5%);
$sidebar-menu__level-2__color--active: #fff;
$sidebar-menu__level-2__bg--active: $sidebar-menu__level-2__bg;
$sidebar-menu__level-2-arrow__color: $sidebar-menu__level-2__color;
$sidebar-menu__level-3__color: #fff;
$sidebar-menu__level-3__bg: $color__secondary;
$sidebar-menu__level-3__color--hover: $sidebar-menu__level-3__color;
$sidebar-menu__level-3__bg--hover: lighten($sidebar-menu__level-3__bg, 5%);
$sidebar-menu__level-3__color--active: #fff;
$sidebar-menu__level-3__bg--active: $sidebar-menu__level-3__bg;
$sidebar-menu__level-3-arrow__color: $sidebar-menu__level-3__color;
$sidebar-menu__level-4__color: #fff;
$sidebar-menu__level-4__bg: $color__secondary;
$sidebar-menu__level-4__color--hover: $sidebar-menu__level-4__color;
$sidebar-menu__level-4__bg--hover: lighten($sidebar-menu__level-4__bg, 5%);
$sidebar-menu__level-4__color--active: #fff;
$sidebar-menu__level-4__bg--active: $sidebar-menu__level-4__bg;
$sidebar-menu__level-4-arrow__color: $sidebar-menu__level-4__color;
$sidebar-menu__level-5__color: #fff;
$sidebar-menu__level-5__bg: $color__secondary;
$sidebar-menu__level-5__color--hover: $sidebar-menu__level-5__color;
$sidebar-menu__level-5__bg--hover: lighten($sidebar-menu__level-5__bg, 5%);
$sidebar-menu__level-5__color--active: #fff;
$sidebar-menu__level-5__bg--active: $sidebar-menu__level-5__bg;
$sidebar-menu__level-5-arrow__color: $sidebar-menu__level-5__color;
// RHS COLUMN
$rhs-column__width: 15.3125rem; // 245px
$rhs-column__margin-left: 2rem; // This variable needs to have same unit as $sidebar-menu__margin-right
// RHS PANEL
$rhs-panel__color: $base__font-colour;
$rhs-panel__bg: #fff;
$rhs-panel__margin-bottom: 2.5rem;
$rhs-panel-heading__color: $color__tertiary;
$rhs-panel-heading__bg: lighten($color__tertiary, 30%);
$rhs-panel-content__color: #58595b;
$rhs-panel-link__color: #333;
$rhs-panel-link__color--hover: $color__primary;
$rhs-panel-link__bg: transparent;
$rhs-panel-link__bg--hover: $rhs-panel-link__bg;
$rhs-panel-link-arrow__color: $color__primary;
$rhs-panel-link-arrow__color--hover: $rhs-panel-link-arrow__color;
// BLOCKQUOTE SETTINGS
$blockquote__background: transparent;
$blockquote__color: #1e264a;
$blockquote-quotemarks__color: $color__primary;
$blockquote-footer__color: #4f4f4f;
// HOMEPAGE CONTENT BOX
$home-box-heading__bg: #fff;
$home-box-heading__bg--hover: $color__tertiary;
$home-box-heading__color: $color__tertiary;
$home-box-heading__color--hover: #fff;
$home-box__bg: #fff;
$home-box-text__color: #2b2b2b;
$home-box-links__color: $base__font-colour;
$home-box-links__color--hover: $color__primary;
$home-box-image-hover-overlay__bg: rgba($color__tertiary, .35);
$home-box-large-line-separator__color: $color__tertiary;
// CAROUSEL
$landscape-carousel-caption__color: #fff;
$landscape-carousel-caption-heading__border: #fff;
$landscape-carousel-caption__bg: transparent;
// TABLE SETTINGS
$table-heading__bg: #fff;
$table-heading__color: $color__primary;
$table-row-odd__bg: transparent;
$table-row-odd__bg--hover: #eee;
$table-row-even__bg: #f7f7f7;
$table-row-even__bg--hover: #eee;
$table-row__border: none;
// FORM SETTINGS
$form-links__color: #393939;
$form-links__color--hover: $color__primary;
$form-separator__color: $color__tertiary;
$form-control__color: inherit;
$form-control__color--focus: #464a4c;
$form-control__bg: #f2f2f2;
$form-control__bg--focus: #f2f2f2;
$form-control__border: none;
$form-control__border--focus: none;
$form-control__border-radius: 0;
$form-control-success__color: #2c882c;
$form-control-success__border: 2px solid $form-control-success__color;
$form-control-warning__color: #aa6708;
$form-control-warning__border: 2px solid $form-control-warning__color;
$form-control-error__color: #e00;
$form-control-error__border: 2px solid $form-control-error__color;
$form-text__font-size: 85%; // of 1rem;
$form-text__color: #636c72;
$form-check__color: $base__font-colour;
$form-check__color--disabled: lighten($base__font-colour, 15%);
// BUTTONS SETTINGS
$btn-primary__color: $color__primary;
$btn-primary__color--hover: #fff;
$btn-primary__bg: #69be28;
$btn-primary__bg--hover: $color__secondary;
$btn-primary__border: #1b325f;
$btn-primary__border--hover: $btn-primary__border;
$btn-secondary__color: #777;
$btn-secondary__color--hover: #fff;
$btn-secondary__bg: transparent;
$btn-secondary__bg--hover: #777;
$btn-secondary__border: #777;
$btn-secondary__border--hover: $btn-secondary__border;
$btn-success__color: #5cb85c;
$btn-success__color--hover: #fff;
$btn-success__bg: transparent;
$btn-success__bg--hover: #5cb85c;
$btn-success__border: #5cb85c;
$btn-success__border--hover: $btn-success__border;
$btn-info__color: #5bc0de;
$btn-info__color--hover: #fff;
$btn-info__bg: transparent;
$btn-info__bg--hover: #5bc0de;
$btn-info__border: #5bc0de;
$btn-info__border--hover: $btn-info__bg--hover;
$btn-warning__color: #f0ad4e;
$btn-warning__color--hover: #fff;
$btn-warning__bg: transparent;
$btn-warning__bg--hover: #f0ad4e;
$btn-warning__border: #f0ad4e;
$btn-warning__border--hover: $btn-warning__border;
$btn-danger__color: #d9534f;
$btn-danger__color--hover: #fff;
$btn-danger__bg: transparent;
$btn-danger__bg--hover: #d9534f;
$btn-danger__border: #d9534f;
$btn-danger__border--hover: $btn-danger__border;
