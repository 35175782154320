/* Module: S */
.quicklinks__wrapper {
    width: 100%;
    max-width: $page__max-width;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    bottom: -1.75rem;
    @include sq-transform(translateX(-50%));
}

h2.quicklinks__heading {
    cursor: default;
    padding-top: .5rem;
    padding-bottom: .5rem;

    &:after {
        content: none;
    }
}

.quicklinks__container {
    display: inherit;
}

.quicklinks {
    position: absolute;
    bottom: 0;
    right: 2rem;
    max-width: pxToRem(300);
    width: 100%;
    box-shadow: 0px 3px 40px 0 rgba(0, 0, 0, 0.4);
}
