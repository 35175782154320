/* Module: S */
.quicklinks__heading {
    display: inline-block;
    padding: .375rem 1.25rem;
    position: absolute;
    left: pxToRem(-119);
    top: pxToRem(-11);
    z-index: 2;
}

.quicklinks__block {
    .quicklinks__heading {
        position: relative;
        padding: pxToRem(5) 2.75rem pxToRem(5) 2rem;
    }
}

.quicklinks__content {
    padding: 1rem 2rem;
}
