/* Module: S */
.page-consultations__hiw,
.page-consultations__completed-consultations,
.page-consultations-details {
    padding: 2rem;
    max-width: $page__max-width;
    margin: auto;
}

.hiw__main-heading {
    h2 {
        font-family: $secondary__font-family;
        font-weight: normal;
        font-style: italic;
        font-size: pxToRem(24);
        color: $color__primary;
        line-height: 1.5;
        &::after {
            content: '';
            display: block;
            width: 18px;
            height: 2px;
            margin-top: 7px;
            background: $color__secondary;
        }
    }
}

.hiw__item {
    text-align: center;
    margin-bottom: 3rem;
}

.hiw__icon {
    max-width: pxToRem(70);
    height: pxToRem(70);
}

.hiw__heading {
    font-family: $base__font-family-medium;
    font-weight: normal;
    font-size: pxToRem(18);
    color: $color__primary;
    padding-bottom: pxToRem(15);
    border-bottom: 2px solid #ddd;
}

.page-consultations__current-consultations {
    background: $color__primary;
    color: #fff;
    padding: 2rem;
}

.current-consultations__content {
    background: $color__secondary;
    font-size: pxToRem(14);
    padding: 1rem;
}

.current-consultations__heading {
    h2 {
        font-size: pxToRem(24);
        font-family: $secondary__font-family;
        font-weight: normal;
        font-style: italic;
        margin-bottom: 2rem;
        &::after {
            content: '';
            display: block;
            width: 18px;
            height: 2px;
            margin-top: 12px;
            background: $color__secondary;
        }
    }
}

.current-consultations__content-meta-status {
    font-family: $base__font-family-bold;
    font-size: pxToRem(14);
}

.current-consultations__content-meta-dates {
    font-family: $secondary__font-family;
    font-style: italic;
    font-size: pxToRem(14);
}

.current-consultations__progress-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.current-consultations__details {
    h3 {
        font-family: $base__font-family-medium;
        font-size: pxToRem(18);
    }
    p {
        font-size: pxToRem(14);
    }
}

.current-consultations__remaining-time {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    @include sq-align-items(center);
    font-size: pxToRem(14);
    font-family: $base__font-family-bold;
}

.current-consultations__time-icon {
    fill: #fff;
    width: 15px;
    height: 15px;
    margin-right: .75rem;
}

.current-consultations__btn-wrapper {
    margin-top: 1.5rem;
}

.completed-consultations__heading {
    h2 {
        font-family: $secondary__font-family;
        font-weight: normal;
        font-style: italic;
        font-size: pxToRem(24);
        color: $color__primary;
        line-height: 1.5;
        &::after {
            content: '';
            display: block;
            width: 18px;
            height: 2px;
            margin-top: 7px;
            background: #69be28;
        }
    }
}

.completed-consultations__item {
    border-top: 1px solid #bbb;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    padding-top: .5rem;
    color: $color__primary;
}

.consultations__item-meta-status {
    font-family: $base__font-family-bold;
    font-weight: normal;
    font-size: pxToRem(14);
    color: $color__primary;
}

.consultations__item-meta-dates {
    font-family: $secondary__font-family;
    font-style: italic;
    font-size: pxToRem(14);
    color: $color__primary;
}

.consultations__item__progress-wrapper {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.consultations__item__details {
    h3 {
        font-family: $base__font-family;
        font-size: pxToRem(18);
    }
}

.consultations__item__btn-wrapper {
    margin-top: 1rem;
}

.page-consultations-details__wrapper {
    border-top: 1px solid #ccc;
    .consultation & {
        margin: auto;
        width: 100%;
    }
}

.page-consultations-details {
    .tool-box {
        display: none;
        margin-top: .5rem;
        margin-bottom: 2.5rem;
    }
}

.consultations-details-item__heading {
    margin-bottom: 1.5rem;
}

.consultations-details__period-heading {
    @include sq-flex(1 1 auto);
    @include sq-flex-box();
    //@include sq-align-items(center);
    font-family: $base__font-family-bold;
    color: $color__primary;
    font-size: pxToRem(14);
    line-height: 1.4;
    margin-bottom: .5rem;
    svg {
        fill: $color__primary;
        width: 15px;
        height: 15px;
        margin-right: 1rem;
        margin-top: .1rem;
    }
}

.consultations-details__period {
    font-family: $secondary__font-family;
    font-style: italic;
    color: $color__primary;
    border-bottom: 1px solid #ccc;
    padding-bottom: .5rem;
    font-size: pxToRem(14);
}

.page-consultations-details__item form {
    li {
        padding-left: 0 !important;
        .form__ele {
            display: inline-block;
        }
        &.form__checkbox {
            padding-left: 0 !important;
            & input[type="checkbox"] {
                margin-right:  10px;
            }
        }
        &.form__radio {
            .form__ele {
                margin-right: 20px;
            }
        }
    }
    li::before{
        content: none !important;
    }
    .sq-form-section-title {
        font-size: 2rem;
        font-weight: bold;
    }
    .sq-form-submit {
        border: none;
    }
    .ajax-form-container {
        margin-top:  1rem;
    }
    .error,
    .target-error {
        color: #ff0000;
    }
}

.bottom-line:not(:last-child) {
    border-top: 0.1px solid #fff;
    margin: 2.5rem 0;
}