$top-heading__bg: #2b2b2b;
$top-heading__color: #fff;
$block-heading__bg: $color__primary;
$block-heading__bg--active: #fff;
$block-heading__border: #02809d;
$block-heading__color: #fff;
$block-heading__color--active: $base__font-colour;
$block-heading-arrow__color: $base__font-colour;
$block-content__bg: $color__primary;
$block-content__color: #fff;
