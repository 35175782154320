/* Module: S */
.landing-image__container {
    height: pxToRem(430);
    overflow: hidden;
    background-color: rgb(13,18,70);
}

.landing-image {
    height: pxToRem(600);
}

.landing-heading {
    h1 {
        font-family: $base__font-family-bold;
        font-size: pxToRem(60);
        margin-bottom: 1rem;
    }
    h2 {
        font-size: pxToRem(24);
    }
}