/* Module: S */
.general-listing__item {
     // margin-bottom: pxToRem(78);
    padding-bottom: pxToRem(77);
}

.general-listing-item__header {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    @include sq-flex-direction(row);
}

.general-listing-header__meta {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    @include sq-flex-basis(70%);
    @include sq-justify-content(flex-end);
    &>div {
        margin-left: 20px;
        margin-right: 0;
        &:first-child {
            margin-left: 0;
        }
    }
}

.general-listing-header__meta-texticon {
    margin-left: pxToRem(20);
}

div.general-listing-header__meta-date {
    position: inherit;
}