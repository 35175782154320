/* Module: S */
.main__footer {
    @include sq-flex-box;
    @include sq-flex-direction;
    @include sq-flex-direction(column);
}

.footer__cta-wrapper {
    width: 100%;
    max-width: $page__max-width;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

.footer__cta {
    @include sq-flex-box();
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-bottom: pxToRem(10);
    &-content {
        font-size: pxToRem(24);
        margin-bottom: 0;
    }
}

.footer__menu {
    li {
        display: inline-block;
        border-right: 1px solid #fff;
        padding-left: 15px;
        line-height: .8rem;
    }
}


/* stylelint-disable */

.footer-links__wrapper {
    @include sq-flex-box;
    @include sq-flex-wrap(wrap);
    @include sq-flex-grow(1);
    @include sq-justify-content(space-between);
    margin-left: auto;
    margin-right: auto;
}


/* stylelint-enable */

.footer-links__column {
    width: auto;
    @include sq-flex-basis(auto);
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}

.footer-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 10px auto;
}

.footer-social__link {
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 1rem;
}

.footer-link,
.footer-phone__link,
.footer-contact__heading {
    font-size: pxToRem(18);
}

.footer-social__wrapper {
    margin-top: 4.5rem;
}