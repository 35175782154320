/* Module: S */
.sidebar__menu {
    //margin-right: $sidebar-menu__margin-right + 2rem;
    //@include sq-flex-shrink(0);
    //@include sq-flex-basis($sidebar-menu__width);
    //@include drop-shadow(.25);
    margin-left: -5.3%;
    @include sq-flex-basis(34%);
    order: 2;
    // padding-bottom: 2.3rem;
    .landing-image__container+.wrapper & {
        margin-top: -4rem;
    }
    &-list:not(.menu__level-2, .menu__level-3, .menu__level-4, .menu__level-5) {
        padding-bottom: 2.3rem;
        // margin-bottom: 2rem;
    }
    &-item {
        max-width: 230px;
    }

}

.news .news__list {
    display: block;
}

.sidebar__menu-title {
    order: 1;
    margin-right: 1rem;
    cursor: default;
}

.sidebar__menu-svg {
    order: 2;
    cursor: default;
}

.sidebar__menu-link {
    .menu__level-2 & {
        padding-left: 1.5rem;
    }
    .menu__level-3 & {
        padding-left: 2.75rem;
    }
    .menu__level-4 & {
        padding-left: 4rem;
    }
    .menu__level-5 & {
        padding-left: 5.25rem;
    }
    &.current.has-children {
        &::after {
            top: 1.15rem;
            right: 1.5rem;
        }
    }
    cursor: default;
}

.rhs__column {
    display: block;
    @include sq-flex-basis($rhs-column__width);
    margin-top: 2rem;
    margin-left: $rhs-column__margin-left;
    .is-listing+& {
        @include sq-flex-shrink(0);
        @include sq-order(inherit);
    }
}

.rhs__panel {
    width: 100%;
}

.rhs-panel__link {
    max-width: $rhs-column__width + 2rem;
}

.form-fieldset__content {
    display: inherit;
    margin-top: 0;
}

.addtocalendar {
    .atcb-list {
        left: auto;
        right: 0;
    }
}

.btt__wrapper {
    padding: 0 2rem;
}

.wrapper {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.main-column__wrapper {
    @include sq-flex-basis(77%);
    .has-sidebar & {
       @include sq-flex-basis(88.5%);
    }
}

.main-content__wrapper {
    max-width: 100%;
    @include sq-flex-direction(row-reverse);
}

.has-sidebar {
    .main__content {
        padding-right: 20%;
    }
}