/* Module: S */
.landscape-carousel__image {
    height: pxToRem(612);
}

.landscape-carousel__controls {
    @include sq-justify-content(flex-end);
    padding-left: 2rem;
    bottom: pxToRem(104);
    padding-right: pxToRem(529.6);
}

.landscape-carousel__caption-wrapper {
    top: calc( (100% - 48px) / 2 );
    @include sq-transform(translate(-50%, -50%));
    @include sq-justify-content(flex-end);
}

.landscape-carousel__image-wrapper {
    height: pxToRem(612);
    width: 1760px;
    margin: 0 auto;
}

.landscape-carousel__item {
    &::before {
        // background-image: url('../mysource_files/overlay-carousel-desktop.png');
        background-image: url('../mysource_files/desktop_colour_overlay.png');
        max-width: pxToRem(1920);
        margin: 0 auto;
    }
}

.landscape-carousel__caption {
    width: auto;
    max-width: pxToRem(570);
    min-width: pxToRem(570);
    margin-right: 0.85rem;
}

.landscape-carousel__caption-content {
    max-width: 90%;
    .btn {
        margin-top: .5rem;
    }
}

.landscape-carousel__caption-heading {
    font-size: pxToRem(60);
    line-height: 1.1;
    margin-bottom: pxToRem(22);
}