/* Module: S */
//Icons
.close-icon {
    display: none;
    .is-active & {
        display: block;
    }
}

.search-icon,
.menu-icon {
    display: block;
    .is-active & {
        display: none;
    }
}

.search-icon,
.menu-icon,
.close-icon {
    fill: $header-search-button__color;
}

//Header
.main-header {
    background: #fff;
    position: relative;
    .is-mobile & {
        z-index: 4;
    }
}

.top-nav {
    margin-left: auto;
    margin-right: auto;
    max-width: $page__max-width;
    padding-left: 1rem;
    padding-right: 1rem;
    @extend %clearfix;
}

.top-nav__wrapper {
    display: none;
    background: $top-nav__bg;
    padding-top: pxToRem(33);
}

.top-nav__list {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
}

.top-nav__item {
    font-family: $secondary__font-family;
    font-style: italic;
    color: $top-nav__color;
    float: left;
    line-height: 1rem;
    padding: 0 1rem;
    position: relative;
    &:last-child {
        padding: 0 0 0 1rem;
        &::after {
            display: none;
        }
    }
}

.top-nav__link {
    font-size: pxToRem(14);
    color: $top-nav__color;
    text-decoration: none;
    &:hover {
        color: $top-nav__color;
        text-decoration: underline;
    }
    &:focus {
        color: $top-nav__color;
    }
    @include add-focus-border();
}

.top-nav-mobile {
    display: block;
    &__items {
        margin: 1rem 0;
        padding: 0;
    }
    &__item {
        font-family: $base__font-family-bold;
        list-style: none;
        margin: 0;
        padding: .35rem 2rem;
        a {
            text-decoration: none;
            color: #000;
        }
    }
}

.main-header__wrapper {
    @include sq-flex-box;
    @include sq-align-items(center);
    @include sq-justify-content(space-between);
    padding: 1rem;
}

.logo__link {
    height: pxToRem(36);
    display: inline-block;
    margin-right: auto;
    @include add-focus-border();
}

.logo__link-text img {
    height: pxToRem(23); 
    margin-bottom: pxToRem(-3); 
}

.logo__image {
    display: inline-block;
    height: 100%;
    // vertical-align: top;
}

.header__logo {
    margin-top: pxToRem(-8);
    margin-right: auto;
    & > a::after {
        background: none !important;
        width: 0;
        height: 0;
        display: block;
        margin: 0;
    }
}

.main-nav {
    display: none;
}

.main-nav__list {
    @include sq-flex-box;
    list-style: none;
    padding: 0;
    margin: 0;
}

.main-nav__item {
    position: relative;
    &:hover {
        &::before {
            transform: scaleX(1);
        }
    }
}

.main-nav__link {
    font-family: $base__font-family-medium;
    font-size: 1rem;
    .active & {
        font-weight: 600;
    }
    @include add-focus-border();
}

.header-search__form {
    position: absolute;
    top: pxToRem(7);
    left: 0;
    width: 100%;
    display: none;
    .sr-only {
        color: #3E7D87;
    }
}

.header-search__input {
    border: none;
    border-top: 0 solid $header-search-input__border;
    padding: 1rem pxToRem(60) 1rem 1.5rem;
    vertical-align: top;
    width: 100%;
    background: $header-search-mobile__bg;
    font-weight: 600;
    @include sq-transition(.15s background-color linear);
    &:focus,
    &:active {
        outline: 0;
        background-color: darken($main-menu__bg, 5%);
    }
}

.header-search__submit-button,
.header-search__close-button {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    padding: .5rem;
    float: right;
}

.header-search__close-button {
    display: none;
    padding: .75rem;
    top: 1.9rem;
    // right: 0;
    right: -9px;
}

//mobile buttons
.main-menu__btn,
.header-search__mobile-btn {
    border: none;
    width: 40px;
    height: 40px;
    background: none;
    padding: .5rem;
    display: inline-block;
    z-index: 1;
}

.main-menu__btn {
    &.is-active svg {
        width: 20px;
        height: 20px;
    }
}

.header-search__mobile-btn {
    width: 35px;
    height: 35px;
}

//Mega menu
.mega-menu__wrapper {
    padding: 0;
    border-top: 1px solid $mega-menu-border__color;
    position: absolute;
    width: 100%;
    display: none;
    height: auto;
    background: $mobile-menu-lvl1__bg;
    .mega-menu__item:last-child {
        border-bottom: 0;
    }
}

.menu-arrow {
    display: block;
    top: 0;
    height: 100%;
    position: absolute;
    right: 0;
    width: pxToRem(60);
    @include sq-transition(.3s all ease-in-out);
    &:hover,
    &:focus {
        outline: 0;
    }
    &::after {
        // border-right: 2px solid $menu-arrow__color;
        // border-bottom: 2px solid $menu-arrow__color;
        background: url('../mysource_files/ico-arrow-down-black.svg') no-repeat;
        content: '';
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        width: .7rem;
        height: .85rem;
        margin-top: pxToRem(-5);
        margin-left: pxToRem(-9);
        // @include sq-transform(rotate(-135deg));
        @include sq-transition(.15s transform linear);
        .lvl-2 & {
            // border-right: 2px solid #fff;
            // border-bottom: 2px solid #fff;
        }
    }
    .is-expanded>.mega-menu__feature &,
    .is-expanded>.mega-menu__sub-nav-title & {
        &::after {
            margin-top: pxToRem(-5);
            margin-left: pxToRem(-10);
            width: .75rem;
            height: .75rem;
            background: url('../mysource_files/close.svg') no-repeat;
            @include sq-transform(rotate(90deg));
        }
    }
}

.mega-menu__close-button {
    display: none;
}

.mega-menu__item {
    padding: 0;
    opacity: 1;
    position: relative;
    border-bottom: 1px solid #ddd;
}

.mega-menu__feature {
    width: 100%;
    padding: 0;
    float: none;
}

.mega-menu__feature-description {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
}

.mega-menu__feature-title,
.mega-menu__sub-nav-title {
    margin-top: 0;
    margin-bottom: 0;
}

.mega-menu__feature-link {
    font-family: $base__font-family-medium;
    position: relative;
    display: block;
    font-size: pxToRem(14);
    // font-weight: 400;
    padding: pxToRem(20) pxToRem(62) pxToRem(20) pxToRem(30);
    text-decoration: none;
    color: #000;
    &:hover,
    &:focus {
        color: $color__primary;
    }
    @include add-focus-border();
}

.mega-menu__navs-wrapper {
    width: 100%;
    display: none;
    overflow: hidden;
    background: $mobile-menu-lvl2__bg;
    margin-top: pxToRem(-10);
    padding-bottom: 1rem;
    .mega-menu__list {
        width: 100%;
        padding: 0;
    }
}

.mega-menu__list {
    list-style: none;
    margin: 0;
    padding: 0;
    .lvl-2 & {
        display: none;
        background: $mobile-menu-lvl3__bg;
        padding-top: .75rem;
        padding-bottom: .75rem;
    }
    .lvl-2 {
        /* 3rd level styling: specificy used to override .lvl-2 & */
        ul.mega-menu__list {
            background: #f5f5f5;
        }
    }
}

.mega-menu__sub-nav-link,
.mega-menu__sub-nav-text {
    position: relative;
    display: block;
    font-size: pxToRem(14);
    font-weight: 400;
    padding: pxToRem(10) pxToRem(62) pxToRem(10) pxToRem(30);
    color: #000;
    text-decoration: none;
    &:hover,
    &:focus {
        // font-family: $base__font-family-medium;
        color: $main-nav__color--hover;
        outline: 0;
    }
}

.mega-menu__sub-nav-text {
    &:hover {
        text-decoration: none;
    }
}

.menu-overlay {
    background: rgba(0, 0, 0, .6);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 4;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    @include sq-transition(.3s opacity ease-in-out,
    .3s visibility ease-in-out);
    &.show {
        opacity: 1;
        visibility: visible;
    }
}