/* Module: S */
.quicklinks {
    position: relative;
}

.quicklinks__heading {
    cursor: pointer;
    position: relative;
    background-color: $top-heading__bg;
    font-size: 1rem;
    font-weight: 600;
    color: $top-heading__color;
    line-height: pxToRem(40);
    margin-top: 0;
    margin-bottom: 0;
    padding: .75rem 1rem;

    &::after {
        content: '';
        position: absolute;
        right: 1.25rem;
        top: 50%;
        margin-top: -8px;
        display: block;
        height: 10px;
        width: 10px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        @include sq-transform(rotate(45deg));
        @include sq-transition(.15s transform linear);
    }

    &[data-expanded="true"] {
        &::after {
            margin-top: -2px;
            @include sq-transform(rotate(-135deg));
        }
    }
}

.quicklinks__container {
    display: none;
}

.quicklinks__block {
    position: relative;
    z-index: 1;

    &.active {
        border-bottom: 2px solid $block-heading__border;

        &:last-child {
            border-bottom: 0;
        }
    }

    .quicklinks__heading {
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        font-size: 100%;
        font-weight: 300;
        text-align: left;
        padding: .25rem 2.75rem .25rem 1rem;
        padding-right: 2.75rem;
        background-color: $block-heading__bg;
        // color: $top-heading__bg;
        border: 0;
        border-bottom: 2px solid $block-heading__border;
        @include sq-transition(.2s background-color linear);

        &:hover,
        &:focus {
            outline: 0;
            background-color: darken($block-heading__bg, 2.5%);
        }

        &::after {
            content: '';
            position: absolute;
            right: 1.25rem;
            top: 50%;
            margin-top: -8px;
            display: block;
            height: 10px;
            width: 10px;
            border-right: 2px solid $block-heading__bg--active;
            border-bottom: 2px solid $block-heading__bg--active;
        }
    }

    &:last-child {
        .quicklinks__heading {
            border-bottom: 0;
        }
    }

    &.active {
        .quicklinks__heading {
            color: $block-heading__color--active;
            background-color: $block-heading__bg--active;
            border-bottom: 0;

            &::after {
                margin-top: -2px;
                @include sq-transform(rotate(-135deg));
                border-color: $block-heading-arrow__color;
            }
        }
    }
}

.quicklinks__content {
    display: none;
    background-color: $block-content__bg;
    padding: .75rem 1rem;
}

.quicklinks__link {
    color: $block-content__color;
    // color: $top-heading__bg;
    line-height: 2;
    display: block;
    text-decoration: none;
    @include sq-transition(.15s color ease-in-out);

    &:focus, &:hover {
        outline: 0;
        text-decoration: underline;
        color: $block-content__color;
        // color: black;
    }
}
