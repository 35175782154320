h1:first-of-type {
    margin-top: 0;
    margin-bottom: .6em;
    line-height: 1;
}

// h2:first-of-type {
//     margin-top: 0;
// }


/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $base__font-family-medium;
    color: $heading__font-color;
    &:not(h1, h2:first-of-type, .search-result__heading, .map__description-heading, .feature-box__heading, .toc__heading) {
        //margin-bottom: .75rem;
        margin-top: 4rem;
    }
}

h1 {
    font-size: pxToRem(50);
}

h2 {
    font-family: $base__font-family;
    font-size: pxToRem(28);
    line-height: pxToRem(36);
    margin-top: 1em;
    margin-bottom: .536em;
}

h3 {
    font-family: $base__font-family;
    font-size: pxToRem(24);
    margin-top: 1.25em;
    margin-bottom: .625em;
}

h4 {
    font-family: $base__font-family;
    font-size: pxToRem(18);
    margin-top: 1.667em;
    margin-bottom: .833em;
}

h5 {
    font-family: $base__font-family;
    font-size: pxToRem(18);
    margin-top: 1.667em;
    margin-bottom: .833em;
}

h6 {
    font-family: $base__font-family;
    font-size: pxToRem(18);
    margin-top: 1.667em;
    margin-bottom: .833em;
}

p {
    margin-top: pxToRem(11);
    margin-bottom: pxToRem(35);
}

hr {
    display: block;
    opacity: .45;
    height: 1px;
    border: 0;
    border-top: 1px solid #a8a8a8;
    margin: 3.5rem 0;
    padding: 0;
}


/* Tables */


/* stylelint-disable */

.responsive__table {
    width: 100%;
    overflow: auto;
    border-bottom: $table-row__border;
    margin: pxToRem(40) 0;
    table {
        min-width: 50rem;
    }
    &:after {
        content: '< Swipe left to view table';
        display: block;        
        font-family: $secondary__font-family;
        font-style: italic;
        color: $color__primary;
        margin-top: 1rem;
        font-size: pxToRem(15);
    }
}

table {
    width: 100%;
    margin: 2px;
    ul,
    ol {
        // Unfortunately no other way to override unordered list mixin with &:not(...)
        >li {
            margin-bottom: .25rem !important;
        }
    }
}

form {
    margin-top: pxToRem(30);
    margin-bottom: pxToRem(30);
}

tbody {
    tr {
        border-bottom: $table-row__border;
        @include sq-transition(.15s background-color linear);
        &:nth-child(odd) {
            background-color: $table-row-odd__bg;
            &:hover {
                background-color: $table-row-odd__bg--hover;
            }
        }
        &:nth-child(even) {
            background-color: $table-row-even__bg;
            &:hover {
                background-color: $table-row-even__bg--hover;
            }
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}

caption {
    @include sq-font-size(.8);
    font-style: italic;
}

th {
    // font-family: $base__font-family-medium;
    font-size: 1rem;
    line-height: 1.45;
    text-align: left;
    color: $table-heading__color;
    padding: pxToRem(30) pxToRem(20) pxToRem(25);
    white-space: nowrap;
    &:last-child {
        border-right: 0;
    }
}

td {
    padding: 1em;
    font-size: pxToRem(14);
    ul,
    ol {
        margin-top: 0;
        margin-bottom: 0;
    }
}

thead {
    border-bottom: 1px solid $color__primary;
}

@include unordered-list($base__font-colour);
@include ordered-list($base__font-colour);

/* Quotes */

blockquote {
    position: relative;
    margin: 0 0 pxToRem(20) 0;
    padding: 1rem;
    min-height: 8rem;
    background: url('../mysource_files/ico-quote.svg') center top no-repeat;
    background-color: $blockquote__background;
    background-size: 9.5rem;
    color: $blockquote__color;
    font-family: $secondary__font-family;
    font-size: pxToRem(20);
    font-style: italic;
    line-height: pxToRem(35);
    text-align: center;
    p {
        margin-top: 0;
        margin-bottom: pxToRem(14);
    }
    footer {
        font-family: $base__font-family;
        font-size: pxToRem(14);
        color: $blockquote-footer__color;
        font-style: normal;
    }
    // font-weight: 600;
    // &::before {
    //     content: '\201C';
    //     font-family: $base__font-family;
    //     font-size: pxToRem(50);
    //     font-weight: 500;
    //     top: 0;
    //     left: 0;
    //     color: $blockquote-quotemarks__color;
    //     position: absolute;
    //     line-height: 1.25;
    //     height: 17px;
    // }
    @-moz-document url-prefix() {
        &::before {
            top: 20px;
        }
        &::after {
            bottom: 15px;
            right: -7px;
        }
    }
}

.pull-quote {
    border-top: 1px solid $base__font-colour;
    padding: 1em .5em 0 .5em;
    margin: 1em;
}


/* Highlight */

.highlight {
    background: #cccccc;
    @extend %inline-block;
    padding: .1em .5em;
}


/* stylelint-enable */

.text-featured {
    font-family: $secondary__font-family;
    font-size: pxToRem(18);
    font-style: italic;
    line-height: 1.67;
    color: $color__secondary;
    margin-bottom: pxToRem(30);
}
