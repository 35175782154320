/* Module: S */
.landing-panel {
    color: $landing-panel__color;
    background-color: $landing-panel__bg;
    border: $landing-panel__border;
    margin-top: $landing-panel__space-between;
    padding: 1rem;
    @include sq-transition(.2s all ease-in-out);
    &:first-child {
        margin-top: 0;
    }
    &:hover,
    &:focus {
        color: $landing-panel__color--hover;
        background-color: $landing-panel__bg--hover;
        border: $landing-panel__border--hover;
    }
}

.landing-panel__link {
    color: $landing-panel__color;
    text-decoration: none;
}

.landing-panel__heading {
    margin-top: 0;
    margin-bottom: .75rem;
}

.landing-panel__image {
    display: block;
    margin-bottom: 1rem;
}

.landing-image__container {
    position: relative;
    height: pxToRem(200);
    overflow: hidden;
}

.landing-image {
    position: relative;
    display: block;
    height: pxToRem(300);
    margin-top: pxToRem(-20);
    width: 100%;
    object-fit: cover;
    z-index: 1;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        z-index: inherit;
    }
    width: 1760px;
    margin: 0 auto;
}

.landing-heading {
    @include sq-flex-box();
    @include sq-flex-direction(column);
    @include sq-justify-content(center);
    width: 100%;
    max-width: $page__max-width;
    height: 100%;
    margin: auto;
    h1 {
        font-family: $base__font-family;
        font-size: pxToRem(28);
        color: #fff;
        margin-bottom: 0;
    }
    h2 {
        font-family: $secondary__font-family;
        font-weight: normal;
        color: #fff;
        font-style: italic;
        font-size: pxToRem(18);
    }
    padding-left: pxToRem(30);
    padding-right: pxToRem(30);
    &__container {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        @include radial-gradient(rgba(1, 25, 61, 0), rgba(1, 25, 61, .5), 0%, 100%);
    }
}

.landing__content {
    h1,
    h2,
    h3 {
        margin-top: 0;
        margin-bottom: .5rem;
    }
    +.panels__container {
        margin-top: 1.5rem;
    }
}