/* Module: S */
.hiw__main-heading {
    h2 {
        font-size: pxToRem(44);
        &::after {
            height: 3px;
            margin-top: 15px;
            width: 30px;
        }
    }
}

.hiw__items {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    margin-bottom: 6rem;
}

.hiw__item {
    @include sq-flex(1 1 33.3%);
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
}

.page-consultations__current-consultations {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    position: relative;
    padding-right: 0;
    padding-bottom: 6.5rem;
}

.current-consultations__heading-wrapper {
    @include sq-flex-box();
    @include sq-flex(1 1 40%);
    @include sq-flex-basis(40%);
    @include sq-justify-content(flex-end); 
    display: block;

}

.current-consultations__heading {
    width: 100%;
    h2 {
        // padding-right: 26%;
        font-size: pxToRem(44);
        line-height: 1.2;
        &::after {
            width: 30px;
            margin-top: 20px;
            height: 3px;
        }
    }
    display: block;
    float: right;
    padding-right: 21%;
}

.current-consultations__content-wrapper {
    @include sq-flex-box();
    @include sq-flex(1 1 60%);
    @include sq-flex-basis(60%);
    position: relative;
}

.current-consultations__content-meta-wrapper,
.current-consultations__progress-wrapper,
.current-consultations__details {
    max-width: pxToRem(744);
}

.current-consultations__content {
    width: 100%;
    margin-top: -7rem;
    padding: 1.75rem;
}

.current-consultations__content-meta-wrapper,
.consultations__item__meta-wrapper {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    @include sq-justify-content(space-between);
}

.current-consultations__content-meta-status {
    font-size: pxToRem(18);
    padding-right: 2rem;
}

.current-consultations__details {
    h3 {
        font-size: pxToRem(28);
        margin-top: 2.5rem;
    }
    p {
        font-size: 1rem;
        margin-bottom: 2rem;
    }
}

.page-consultations__completed-consultations {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    margin-top: 3rem;
}

.completed-consultations__item {
    margin-bottom: 4rem;
}

.completed-consultations__heading {
    padding-right: 15%;
    h2 {
        font-size: pxToRem(44);
        margin-top: 1.5rem;
        line-height: 1.2;
        &::after {
            width: 30px;
            margin-top: 20px;
            height: 3px;
        }
    }
}

.consultations__item-meta-status {
    font-size: pxToRem(18);
}

.consultations__item__details {
    h3 {
        font-size: pxToRem(28);
        line-height: 1.4;
    }
    p {
        font-size: 1rem;
        margin-bottom: 2rem;
    }
}

.page-consultations-details {
    .tool-box {
        display: block;
    }
}

.main__content.page-consultations-details__wrapper {
    padding-right: 0;
}

.page-consultations-details__item {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    margin-bottom: 1.5rem;
}

.consultations-details-item__heading {
    @include sq-flex-basis(35%);
    padding-right: 5%;
    h3 {
        margin-top: 1rem;
        font-size: pxToRem(36);
        line-height: 1;
    }
}

.consultations-details-item__content {
    @include sq-flex-basis(65%);
}

.consultations-details__period {
    display: inline-block;
}

.page-consultations-details__item form {
    li {
        padding-left: 0;
    }
    li::before{
        content: none !important;
    }
}