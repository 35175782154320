/* Module: S */
/*
--------------------
Pages
--------------------
*/

@media screen and (min-width: 63.75em) {
    .tooltip__text {
        min-width: 300px;
    }
}

.col-2-left.long-page .layout-left.toc-hidden {
    width: 100%;
}

.lhs-toc {
    display: none;
    position: relative;
}

@media screen and (min-width: 63.75em) {
    .lhs-toc {
        display: block;
    }

    .lhs-toc.toc-hidden {
        display: none;
    }

    .long-page .lhs-toc {
        left: auto !important;
        padding-right: 20px;
        position: absolute;
        top: 50px;
        width: 23.5%;
        overflow-y: scroll;
        margin-top: 30px;
    }

    .long-page.is-sticky-menu .lhs-toc {
        left: auto !important;
        position: fixed !important;
        background: #fff;
    }
}

@media screen and (min-width: 82.5em) {
    .long-page .lhs-toc {
        width: 300px;
    }
}

@media screen and (min-width: 63.75em) {
    .rhs-toc {
        display: none;
    }
}

.toc {
    position: static;
    top: auto;
    width: 100%;
}

.toc-helper h3 {
    margin-top: 0;
    font-size: 16px;
    font-size: 1rem;
    font-family: 'GothamHTF-Bold';
}

.toc-helper ul {
    padding: 0;
    overflow: hidden;
}

.toc__list .toc__item,
.toc__list .toc__sub-item,
.toc__list .toc__sub-sub-item {
    padding: 8px 0;
}

.toc__list .toc__item a,
.toc__list .toc__sub-item a,
.toc__list .toc__sub-sub-item a {
    text-decoration: none;
    border-bottom: 0;
}

.toc__list .toc__item.is-current > a,
.toc__list .toc__sub-item.is-current > a,
.toc__list .toc__sub-sub-item.is-current > a {
    font-weight: bold;
    text-decoration: underline;
}

.toc__list .toc__item.is-current .toc__sub-item a,
.toc__list .toc__sub-item.is-current .toc__sub-item a,
.toc__list .toc__sub-sub-item.is-current .toc__sub-item a {
    font-weight: normal;
    text-decoration: none;
}

a.lhs-toc__switch {
    display: none;
    left: 340px;
    position: absolute;
    top: 314px;
    width: 25px;
    height: 25px;
    background: darkgray;
    text-indent: -999em;
    border-bottom: 0;
    cursor: pointer;
    &:hover {
        border-bottom: 0;
    }
    &::after {
        content: '';
        position: absolute;
        top: pxToRem(8);
        left: .55rem;
        display: block;
        height: .65rem;
        width: .65rem;
        border-right: 3px solid #fff;
        border-bottom: 3px solid #fff;
        @include sq-transform(rotate(135deg));
    }

}

@media screen and (min-width: 63.75em) {
    a.lhs-toc__switch {
        display: block;
    }
}

.layout-left.toc-hidden a.lhs-toc__switch {
    left: 0;
    &::after {
        left: unset;
        right: .55rem;
        @include sq-transform(rotate(-45deg));
    }
}

@media screen and (min-width: 63.75em) {
    .long-page .layout-left {
        padding-left: pxToRem(350);
        &.toc-hidden {
            padding-left: pxToRem(50);
        }
    }
}
  
.long-page .wrapper {
    position: relative;
}

.long-page .main-content__wrapper {
    display: block;
}

.long-page .rhs-toc .toc__list {
    display: block;
    list-style-type: none;
}

.long-page .rhs-toc .toc__list>li {
    padding-left: 0 !important;
    margin-bottom: 0 !important;
}

.long-page .rhs-toc .toc__list li:before {
    display: none !important;
}