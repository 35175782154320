/* Module: S */
.main-header__wrapper {
    position: relative;
    margin: 0 auto;
    max-width: $page__max-width;
    padding-top: 0;
    padding-bottom: 0;
}

.header-search__wrapper {
    position: relative;
    width: pxToRem(40);
    border: none;
    margin-top: -12px;
    height: pxToRem(50);
    //@include sq-transition(.3s all ease-in-out);
    .header-search__form {
        display: none;
    }
    &.is-open {
        @include sq-flex-box;
        position: relative;
        width: 40%;
        @include sq-transition(.3s all ease-in-out);
        .header-search__submit-button {
            display: none;
        }
        .header-search__close-button {
            display: block;
        }
        .header-search__form {
            display: block;
        }
    }
}

.header__logo {
    margin-top: pxToRem(-24);
}

.top-nav__wrapper {
    display: block;
}

.top-nav-mobile {
    display: none;
}

.twitter-typeahead {
    border-bottom: 1px solid #a8a8a8;
}

.header-search__input {
    position: relative;
    border: none;
    padding: pxToRem(10) pxToRem(35) pxToRem(10) .2rem;
    vertical-align: top;
    background: none;
    &:focus {
        background-color: $header-search-focus__bg!important;
    }
    &::-ms-clear {
        display: none;
    }
}

.header-search__submit-button {
    display: block;
    float: none;
    position: absolute;
    top: 10px;
    // right: 3px;
    right: -9px;
    width: 35px;
    @include ie-transparent();
    @include sq-transform(none);
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
}

.header-search__close-button {
    position: absolute;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
}

.header-search__form {
    height: auto;
    position: relative;
    @include sq-flex-box();
}

.main-nav {
    @include sq-flex-box();
    @include sq-flex(1 0 auto);
    @include sq-flex-direction(row-reverse);
    min-height: pxToRem(91);
}

.main-nav__link {
    color: $main-nav__color;
    text-decoration: none;
    padding: pxToRem(36) 1.25rem pxToRem(35) 1.25rem;
    display: block;
    line-height: 1.25;
    @include sq-transition(.2s color linear);
    &:hover,
    &:focus {
        color: $main-nav__color--hover;
    }
    &::after,
    &::before {
        @include sq-transition(.3s ease opacity);
    }
    &::after {
        content: '';
        bottom: 0;
        display: block;
        width: 50px;
        height: 20px;
        background-color: $main-nav-top-bar__color;
        position: absolute;
        left: 50%;
        opacity: 0;
        @include sq-transform(translate(-50%, 0));
    }
    &::before {
        bottom: 0;
        content: '';
        position: absolute;
        left: 50%;
        display: block;
        height: .75rem;
        width: .75rem;
        border-right: 3px solid #fff;
        border-bottom: 3px solid #fff;
        opacity: 0;
        z-index: 2;
        @include sq-transform(translate(-50%, 0) rotate(-135deg));
    }
    &.main-nav__link--active {
        &::after {
            opacity: 1;
        }
        &::before {
            opacity: 1;
        }
    }
}

.menu-arrow {
    display: none;
}

.mega-menu__wrapper {
    padding: pxToRem(36) 0;
    display: block;
    width: 100%;
    height: 400px;
    background: $mega-menu__bg;
    overflow: hidden;
}

.mega-menu__items {
    max-width: $page__max-width;
    margin: 0 auto;
    position: relative;
}

.mega-menu__close-button {
    background: none;
    border: none;
    padding: 0;
    display: block;
    height: 18px;
    width: 18px;
    position: absolute;
    right: 2rem;
    top: 0;
    .close-icon {
        display: block;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
}

.mega-menu__item {
    position: absolute;
    left: 50%;
    width: 100%;
    max-width: $page__max-width;
    padding-left: 2rem;
    padding-right: 3.5rem;
    opacity: 0;
    visibility: hidden;
    border-bottom: 0;
    @include sq-transform(translateX(-50%));
    @include sq-transition(.3s ease-in-out opacity, .3s ease-in-out visibility);
    &.mega-menu__item--visible {
        opacity: 1;
        visibility: visible;
    }
}

.mega-menu__feature {
    width: 30%;
    padding-right: 4rem;
    float: left;
    margin-top: -7px;
}

.mega-menu__feature-title {
    margin-bottom: .75rem;
}

.mega-menu__feature-description {
    display: block;
    line-height: 1.56;
    color: $mega-menu-sub-nav-link__color;
}

.mega-menu__feature-link {
    color: $mega-menu-link__color;
    border: none;
    text-decoration: none;
    font-weight: bold;
    font-size: pxToRem(24);
    padding: 0;
    &:hover {
        text-decoration: underline;
    }
}

.mega-menu__navs-wrapper {
    background: none;
    display: block;
    float: left;
    width: 70%;
    padding-top: 0;
    padding-bottom: 0;
    >.mega-menu__list {
        width: 33%;
        padding-right: 3rem;
        &:nth-child(3n) {
            padding-right: 0;
        }
        &:nth-child(n+4) {
            margin-top: 2rem;
        }
    }
}

.mega-menu__list {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;
    .lvl-2 & {
        display: block;
        background: none;
        padding-top: 0;
        padding-bottom: 0;
    }
    .lvl-2 {
        /* 3rd level styling: specificy used to override .lvl-2 & */
        ul.mega-menu__list {
            background: #fff;
        }
    }
}

.mega-menu__sub-nav-title {
    margin-top: 0;
    margin-bottom: .5rem;
    .mega-menu__sub-nav-link,
    .mega-menu__sub-nav-text {
        font-size: 1.05rem;
        color: $mega-menu-sub-nav-title__color;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.mega-menu__sub-nav-link,
.mega-menu__sub-nav-text {
    color: $mega-menu-sub-nav-link__color;
    display: inline-block;
    font-weight: inherit;
    padding: .2rem 0;
    text-decoration: none;
    @include sq-transition(.1s color ease-in-out);
    &:hover,
    &:focus {
        color: $main-nav__color--hover;
        text-decoration: underline;
    }
}

.mega-menu__sub-nav-text {
    font-weight: 600;
    &:hover {
        text-decoration: none;
    }
}

.main-menu__btn,
.header-search__mobile-btn {
    display: none;
}