/* Module: S */
/* stylelint-disable */
.main__footer {
    color: $footer-link__color;
    background-color: $footer__bg;    
    padding-left:pxToRem(20);
    padding-right: pxToRem(20);
    padding-bottom: 2rem;
} 

.footer__cta {
    padding-top: pxToRem(30);
    padding-bottom: pxToRem(20);
    margin-bottom: pxToRem(30);
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,.25);    

    &-content {
        font-family: $secondary__font-family;
        font-style: italic;
        font-size: pxToRem(18);
        margin-bottom: pxToRem(20);
    }
}

.footer__menu {
    list-style: none;
    padding: 0 0;
    margin: 0;

    li {
        display: block;
        border-right: none;
        line-height: 1.5rem;
        font-size: pxToRem(14);
        font-family: $secondary__font-family;
        font-style: italic;
        margin: pxToRem(20) 0;
        
        a {
            color: #fff;
            text-decoration: none;
            margin-right: 20px;
            
            &:hover {
                text-decoration: underline;
            }
        }

        &:first-child {
            padding-left:0;
        }

        &:last-child {
            border-right: none;
        }

    }
}

/* stylelint-enable */

.footer-links__list,
.footer-social__list,
.footer-contact__list {
    @extend %list-reset;
}

.footer-links__item,
.footer-contact__item {
    font-size: 1rem;
    margin-bottom: .75rem;
    font-weight: 300;

    &:last-child {
        margin-bottom: 0;
    }
}

/* stylelint-disable */
.footer-links__wrapper {
    width: 100%;
    max-width: 80rem;
}
/* stylelint-enable */

.footer-links__column {
    margin-bottom: 2.5rem;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        content: '';
        display: inline-block;
        width: 3rem;
        height: 4px;
        margin-bottom: 2rem;
        background-color: $footer-column-border__color;
    }
}

.footer-link,
.footer-phone__link,
.footer-contact__heading {
    font-size: pxToRem(18);
    color: $footer-link__color;
    text-decoration: none;
    line-height: 1.5;
    display: inline-block;

    &:not(.footer-contact__heading):hover,
    &:not(.footer-contact__heading):focus {
        color: $footer-link__color--hover;
        text-decoration: underline;
    }
}

.footer-contact__heading {
    color: $footer-link__color;
    display: block;
    font-weight: inherit;
    margin-top: 0;
    margin-bottom: .5rem;
}

/* stylelint-disable */
.footer-social__wrapper {
    @include sq-flex-box;
    @include sq-justify-content(center);
    background-color: $footer-social___bg;
    margin-top: 3rem;
    margin-left: -2rem;
    margin-right: -2rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
}
/* stylelint-enable */

.footer-icon {
    fill: #fff;
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    margin: 10px auto;
}

.footer-social__link {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: .5rem;
    background-color: $footer-social-icons__bg;
    border-radius: .35rem;
    @include sq-transition(.1s background-color ease-in-out);

    .sr-only {
        color: #fcffff;
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover,
    &:focus {
        background-color: #fff;
        outline: none;

        &.facebook {
            .footer-icon {
                fill: #3b5998;
            }
        }

        &.youtube {
            .footer-icon {
                fill: #bb0000;
            }
        }

        &.linkedin {
            .footer-icon {
                fill: #0077b5;
            }
        }

        &.instagram {
            .footer-icon {
                fill: #bc2a8d;
            }
        }

        &.twitter {
            .footer-icon {
                fill: #0084b4;
            }
        }
    }
}
