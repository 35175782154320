/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .homepage-infoblocks__element { ... }
// .homepage-infoblocks__element--modifier { ... }
// .homepage-infoblocks__sub-element { ... }

.homepage-infoblocks {
    &__header {
        font-size: pxToRem(44);
        line-height: 1.18;
    }
    &__heading {
        font-size: pxToRem(28);
        line-height: 1.36;
    }
}