/* Module: S */
.homepage-stats__wrapper-outer {
    // max-width: $page__max-width;
    // margin: auto;
}

.homepage-stats__wrapper {
    display: block;
}

.homepage-stats__header {
    @include sq-flex(1 1 100%);
    position: relative;
    order: 1;
}

.homepage-stats__heading {
    font-family: $secondary__font-family;
    font-style: italic;
    font-size: pxToRem(24);
    padding: 2.2rem 2rem 1rem;
    color: #fff;
    line-height: 1.3;
    width: 100%;
    // position: absolute;
    background: $color__secondary;
    order: 1;
    &::after {
        content: '';
        display: block;
        background: #fff;
        height: 2px;
        width: 18px;
        margin-top: .5rem;
    }
    br {
        display: none;
    }
}

.homepage-stats__content {
    @include sq-flex(1 1 100%);
    @include sq-flex-box();
    @include sq-justify-content(flex-start);
    @include sq-flex-direction(column);
    align-items: left;
    background: $color__secondary;
    color: #fff;
    order: 2;
    padding-bottom: 1.5rem;
}

.homepage-stats__item {
    padding: 1rem 2rem;
    // min-width: auto !important;
    // min-height: 188px;
}

.homepage-stats__item-number {
    font-family: $base__font-family-medium;
    font-size: pxToRem(24);
    margin-bottom: .25rem;
}

.homepage-stats__item-desc {
    font-family: $base__font-family;
    font-size: pxToRem(14);
}