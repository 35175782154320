/* Module: S */
/* Typeahead */

$borders: $color__primary;
$font: $color__tertiary;
$background: #f2f2f2;
$background_active: #f2f2f2;
$default-transition: .4s cubic-bezier(.77, 0, .175, 1);
.twitter-typeahead {
    width: 100%;
    border-bottom: 0;
    .search-page & {
        // margin-right: .5rem;
        -ms-flex-negative: 1;
    }
}

.search-page .form__group-inline {
    height: pxToRem(40);
    .btn {
        padding-top: 10px;
        margin-bottom: 0;
    }
}

.tt-menu {
    font-family: $base__font-family-medium;
    background-color: $background;
    color: $color__secondary;
    margin-top: pxToRem(-1);
    text-align: left;
    width: 100%;
    //display: block !important;
    padding: pxToRem(15) pxToRem(15);
    // border: 1px solid $borders;
    .rgba & {
        background-color: $background;
    }
    .is-opened & {
        margin-top: 0;
    }
    .tt-dataset::before {
        padding-left: pxToRem(15);
        content: 'Suggestions';
        display: block;
        font-family: $secondary__font-family;
        font-style: italic;
        font-size: pxToRem(14);
    }
}

.form__control.typeahead {
    border-width: 1px;
    height: 100%;
    &:focus {
        border-width: 1px;
    }
}

.tt-selectable {
    @include sq-transition(border-color $default-transition, background-color $default-transition);
    cursor: pointer;
    font-size: pxToRem(14);
    padding: pxToRem(10) pxToRem(16);
    color: #4f4f4f;
    &:not(:last-child) {
        // border-color: $borders;
        // border-style: solid;
        // border-width: 0 0 pxToRem(1);
        .rgba & {
            //border-color: $borders;
        }
    }
    &:focus,
    &:hover,
    &.tt-cursor {
        color: $font;
        background-color: $background_active;
    }
}