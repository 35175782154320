// HTML 5 Boilerplate Print Stylesheet
* {
    background: transparent !important;
    color: #000 !important; // Black prints faster: h5bp.com/s
    box-shadow: none !important;
    text-shadow: none !important;
}

a,
a:visited {
    text-decoration: underline;
}


/* stylelint-disable */

a[href]:after {
    content: " (" attr(href) ")";
}

abbr[title]:after {
    content: " (" attr(title) ")";
}


/* stylelint-enable */

// Don't show links for images, or javascript/internal links
.ir a::after,
a[href^='javascript:']::after,
a[href^='#']::after {
    content: '';
}

pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
}

thead {
    display: table-header-group; // h5bp.com/t
}

tr,
img {
    page-break-inside: avoid;
}

img {
    max-width: 100% !important;
}

p,
h2,
h3 {
    orphans: 3;
    widows: 3;
}

h2,
h3 {
    page-break-after: avoid;
}

.landing-image__container {
    height: auto;
    img {
        display: none;
    }
}

.offset-top {
    margin-top: 0 !important;
}

.sidebar__menu-link:hover::before,
.sidebar__menu-link.active::before,
.sidebar__menu-link.current::before {
    border: .0625rem solid #000;
}

.sidebar__menu-item {
    border-bottom: 1px solid #ccc;
}

.mega-menu__close-button {
    display: none;
}

.btn-subscribe {
    background: #000 !important;
    &::before {
        border-right: 1px solid #fff;
    }
}

.form__control {
    border: 1px solid #000;
}

.btn {
    background: #aaa !important;
}

.form-check__input,
.sq-form-field {
    position: relative !important;
    opacity: 1 !important;
    display: block !important;
    margin-left: auto !important;
}