/* Module: S */
.homepage-stats__wrapper {
    @include sq-flex-box();
    @include sq-flex-direction(row);
}

.homepage-stats__header {
    @include sq-flex(1 1 100%);
    position: relative;
    order: 2;
}

.homepage-stats__heading {
    font-size: pxToRem(44);
    background: $color__primary;
    padding: 3.2rem 3.5rem;
    &::after {
        background: $color__secondary;
        width: 30px;
        margin-top: 1.5rem;
    }
    br {
        display: block;
    }
}

.homepage-stats__content {
    order: 1;
    @include sq-flex(1 1 100%);
    @include sq-justify-content(flex-end);
    align-items: center;
    padding: 5.5rem 2rem;
}

.homepage-stats__item {
    margin-left: 1.65rem;
    min-width: 200px !important;
}

.homepage-stats__item-number {
    font-family: $base__font-family-bold;
    font-size: pxToRem(36);
    margin-bottom: 1rem;
}

.homepage-stats__item-desc {
    font-family: $base__font-family-medium;
}


/* custom MQ for inbetween resolutions */

// @media screen and (min-width: 1663px) and (max-width: 1920px) {
//     .homepage-stats__content {
//         @include sq-flex(1 1 66.5%);
//     }
//     .homepage-stats__header {
//         @include sq-flex(1 1 33.5%);
//     }
// }
// @media screen and (min-width: 1080px) and (max-width: 1662px) {
//     .homepage-stats__content {
//         @include sq-flex(1 1 64.5.5%);
//     }
//     .homepage-stats__header {
//         @include sq-flex(1 1 35.5%);
//     }
// }