/* Module: S */
.landscape-carousel {
    display: none;
    &.slick-initialized {
        display: inherit;
    }
}

.landscape-carousel,
.landscape-carousel__caption-wrapper {
    position: relative;
    overflow: hidden;
}

.landscape-carousel__caption-wrapper {
    @include sq-flex-box;
    @include sq-justify-content(flex-start);
    width: 100%;
    max-width: $page__max-width;
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 2rem;
    position: absolute;
    top: pxToRem(65);
    left: 50%;
    z-index: 1;
    @include sq-transform(translate(-50%, 0));
}

.landscape-carousel__wrapper {
    position: relative;
}

.landscape-carousel__item {
    background-color: $landscape-carousel-caption__bg;
    overflow: hidden;
    background-color: #0d1246;
    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        background-image: url('../mysource_files/overlay-carousel-mobile.png');
        background-size: cover;
        background-position: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.landscape-carousel__caption {
    font-family: $base__font-family-medium;
    color: $landscape-carousel-caption__color;
    text-decoration: none;
    background-color: $landscape-carousel-caption__bg;
    display: block;
    position: relative;
    line-height: 1.25;
    &:hover,
    &:focus {
        color: $landscape-carousel-caption__color;
        .landscape-carousel__caption-heading::after {
            width: 100%;
        }
    }
    &.is-video {
        &::after {
            content: 'Watch Video';
            display: inline-block;
            font-weight: 600;
            color: #fff;
            border: 2px solid #fff;
            padding: pxToRem(10) pxToRem(14) pxToRem(8);
            margin-top: 1.5rem;
        }
    }
}

.landscape-carousel__image-wrapper {
    background-size: cover !important;
    /* inline css override */
    height: pxToRem(650);

    &-disableParallax {
        /* override parallax on mobile */
        transform: translateX(0) !important;
        // width: auto !important;
    }
}

.landscape-carousel__image {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: pxToRem(600);
}

.landscape-carousel__caption-preheading {
    font-size: pxToRem(18);
    font-family: $secondary__font-family;
    font-style: italic;
    padding-left: pxToRem(35);
    margin-bottom: pxToRem(12);
    &::before {
        content: '';
        display: block;
        height: 2px;
        width: 20px;
        position: absolute;
        top: 11px;
        left: 0;
        background: #69be28;
    }
}

.landscape-carousel__caption-heading {
    font-family: $base__font-family-medium;
    font-size: pxToRem(28);
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: .75rem;
    padding-bottom: .75rem;
}

.landscape-carousel__caption-content {
    font-size: pxToRem(14);
    line-height: 1.86;
    .btn {
        padding: .75rem 1.25rem .75rem 3rem;
    }
    p {
        margin-bottom: 1.5rem;
    }
}

.landscape-carousel__dots {
    @include sq-flex-box;
    @include sq-flex-direction(row);
    @extend %list-reset;
    >li {
        margin-right: .75rem;
        button {
            display: block;
            height: pxToRem(11);
            width: pxToRem(11);
            border-radius: 50%;
            background-color: rgba(0, 0, 0, .15);
            border: pxToRem(2) solid #fff;
            margin: 4px 0;
            padding: 0;
            color: #757575;
            text-indent: 200%;
            white-space: nowrap;
            overflow: hidden;
        }
        &.slick-active {
            button {
                background-color: #fff;
            }
        }
    }
}


/* stylelint-disable */

.landscape-carousel__controls {
    @include sq-flex-box;
    @include sq-justify-content(flex-start);
    @include sq-align-items(center);
    width: 100%;
    max-width: $page__max-width;
    margin: 0 auto;
    padding-left: 1.75rem;
    padding-right: 1rem;
    bottom: 28%;
    left: 50%;
    @include sq-transform(translateX(-50%));
    position: absolute;
}


/* stylelint-enable */

.landscape-carousel__toggler {
    // display: none;
    background-color: transparent;
    height: pxToRem(11);
    width: pxToRem(12);
    border-width: 0 pxToRem(4) 0 pxToRem(4);
    border-style: none solid;
    border-color: transparent #fff;
    padding: 0;
    margin-left: .25rem;
    &.carousel-paused {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 10px;
        border-color: transparent transparent transparent #fff;
    }
}