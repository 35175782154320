/* Module: S */
.progressbar {
    height: 16px;
    position: relative;
    overflow: hidden;
    background: #ddd;
    &>span {
        display: block;
        height: 16px;
        background-color: #bbb;
        position: relative;
        overflow: hidden;
    }
    &.progressbar-green {
        background: #c5b8d5;
        &>span {
            background-color: #69be28;
        }
    }
}