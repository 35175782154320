/* Module: S */
.rhs-filters.form-filters,
.rhs-filters.facets-filters {
    .facet {
        margin-bottom: 2rem;
    }
    fieldset.form__group {
        border: 0;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            position: relative;
            padding-top: 1.75rem;
            margin-bottom: 2rem;
        }
    }
    .rhs-filters__heading {
        border-bottom: 2px solid $color__primary;
        &::before {
            content: none;
        }
        +form {
            display: block;
        }
    }
    legend {
        color: $color__quaternary;
        background-color: transparent;
        padding: 0;
        // margin: 0 0 1rem 0;
        margin: 0;
        font-size: 125%;
        font-weight: 500;
        text-transform: none;
        width: auto;
        display: inline-block;
        cursor: default;
        &::before {
            content: none;
        }
        +.form-fieldset__content {
            margin-top: 1rem;
        }
        &::after {
            content: '';
            display: block;
            width: 1.25rem;
            height: 3px;
            margin-top: .25rem;
            background-color: $color__primary;
        }
        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            position: absolute;
            top: -13px;
        }
    }
}

.rhs__column-listing {
    margin-left: 0;
    margin-right: 3rem;
}