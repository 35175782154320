/* Module: S */
.landing-heading__container {
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    background-image: url('../mysource_files/overlay-header-mobile.png');
    background-size: cover;
    background-position: center center;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.countdown-widget__container {
    display: none;
    position: relative;
    z-index: 3;
    top: 0;
    color: #fff;
    background-color: rgba($color__secondary, 1);
    font-family: $base__font-family;
    font-weight: 1000;
    font-size: pxToRem(14.5);
    width: 100%;
    padding: 0 pxToRem(30);
    line-height: pxToRem(25.6);

    p {
        margin: 0;
    }

    .countdown__time {
        display: inline-block;
        font-size: pxToRem(16);
    }

    .countdown__link {
        display: inline-block;
        font-family: $secondary__font-family;
        color: rgba(99,181,41,1);
        text-decoration: none;
        font-style: italic;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &::before {
            display: inline-block;
            content: '-';
            width: pxToRem(10);
            height: auto;
            color: #fff;
            margin-left: pxToRem(10);
        }
    }
}
