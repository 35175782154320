/* Module: S */
.homepage-infoblocks {
    max-width: $page__max-width;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    margin: auto;
    margin-top: -7rem;
    width: 100%;
    &__item {
        position: relative;
        vertical-align: top;
        z-index: 1;
        margin-bottom: .5rem;
        @include sq-transition(all .3s);
        &:hover {
            @include sq-transition(all .3s);
            background: transparent;
        }
    }
    &__header {
        font-family: $secondary__font-family;
        font-style: italic;
        font-size: pxToRem(24);
        color: #fff;
        margin-bottom: pxToRem(20);
        margin-left: pxToRem(-5);
        &:hover {
            background: transparent !important;
        }
        &::after {
            content: '';
            display: block;
            height: 3px;
            width: 30px;
            background: #69be28;
            margin-top: pxToRem(10);
            margin-left: pxToRem(5);
        }
    }
    &__link {
        text-decoration: none;
        position: relative;
        @include add-focus-border();
    }
    &__title {
        @include sq-flex-box();
        @include sq-flex-direction(row);
        @include sq-justify-content(space-between);
        font-family: $base__font-family-medium;
        font-size: pxToRem(18);
        padding: .5rem 0;
        padding-bottom: 0;
    }
    &-image__wrapper {
        overflow: hidden;
    }
    &__image {
        // @include sq-transition(all .5s); 
        /* inline overrides in DOM*/
        background-size: cover !important;
        background-position: center center !important;
        height: pxToRem(90);
        width: pxToRem(1000);
        margin-left: -50%;
        &-disableParallax {
            /* override parallax on mobile */
            transform: translateX(0) !important;
            width: auto !important;
        }
    }
    &__heading {
        margin-bottom: 1rem;
    }
    &__icon {
        width: 18px;
        height: 18px;
        svg {
            width: 100%;
            height: 100%;
            fill: $color__primary;
            path {
                fill: $color__primary;
            }
        }
    }
    &__content {
        display: none;
        border-top: 1px solid #cacaca;
        padding-top: .75rem;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-bottom: 1rem;
        font-size: pxToRem(15);
    }
}