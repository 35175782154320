/* Module: S */
.inside-header {
    margin-bottom: pxToRem(70);
    h1 {
        font-family: $base__font-family-bold;
        font-size: pxToRem(44);
        line-height: 1.3;
    }
    h2 {
        /*.main-content h2 override*/
        font-size: pxToRem(24) !important;
    }
}