/* Module: S */
.homepage-stats__wrapper {
    @include sq-flex-box();
    @include sq-flex(1 1 100%);
    @include sq-flex-direction(column);
    display: block;
}

.homepage-stats__content {
    @include sq-flex(1 1 100%);
    @include sq-justify-content(flex-start);
    @include sq-flex-direction(row);
    align-items: left;
    padding-top: 0;
}

.homepage-stats__item { 
   min-width: auto !important;
   min-height: 200px;
}
